var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "iconBox" },
    [
      _c("el-input", {
        ref: "search",
        staticStyle: { width: "300px" },
        attrs: { placeholder: "输入关键词搜索,注意全是英文", clearable: "" },
        on: {
          input: function ($event) {
            return _vm.upIcon(_vm.iconVal)
          },
        },
        model: {
          value: _vm.iconVal,
          callback: function ($$v) {
            _vm.iconVal = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "iconVal",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "icons-container" }, [
        _c(
          "div",
          { staticClass: "grid" },
          _vm._l(_vm.list, function (item) {
            return _c(
              "div",
              {
                key: item,
                on: {
                  click: function ($event) {
                    _vm.handleClipboard(
                      _vm.generateElementIconCode(item),
                      $event,
                      item
                    )
                  },
                },
              },
              [
                _c("el-tooltip", { attrs: { placement: "top" } }, [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.generateElementIconCode(item)) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-item" }, [
                    _c("i", { class: "el-icon-" + item }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item))]),
                  ]),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }